@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  padding: 0 30px;
  width: 100%;
  margin-bottom: 60px;
  @include breakpoint(sm) {
    margin-bottom: 10px;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: 11px;
    @include breakpoint(sm) {
      font-size: 14px;
    }
  }

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(sm) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }
}

.paymentsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 24px;

  @include breakpoint(sm) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 13px;
    row-gap: 10px;
  }
}

.radio {
  width: 42px;
  height: 42px;
  position: absolute;

  & > div {
    width: 42px;
    height: 42px;
  }

  svg {
    @include breakpoint(sm) {
      width: 16px;
      height: 16px;
    }
  }
}

.content {
  display: flex;
  flex-flow: wrap column;
  flex: 1;
  align-items: center;
  padding: 12px 0 8px 0;
  @include breakpoint(sm) {
    padding: 22px 0 10px 0;
  }
}

.name {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: $color-label;
  width: 100%;
  text-align: center;
  @include breakpoint(sm) {
    font-size: 10px;
    margin-bottom: 10px;
  }
}

.commission,
.cashDelivery {
  position: absolute;
  right: 26px;
  top: 15px;
  @include breakpoint(sm) {
    top: 10px;
    right: 10px;
  }

  span {
    display: block;
    text-align: right;
    color: $color-text-dark;

    &:first-child {
      font-size: 10px;
      line-height: 11px;
      @include breakpoint(sm) {
        font-size: 8px;
      }
    }

    &:last-child {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      @include breakpoint(sm) {
        font-size: 10px;
      }
    }
  }

  button {
    border: unset;
    background-color: transparent;
    color: $color-primary;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
  }
}

.cashDeliveryValue {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-top: 10px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #cccfd5;
}

.icon {
  display: block;
  max-width: 32px;
}

.item {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid #cccfd5;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;

  &:hover,
  &.active {
    border-color: $color-primary;
  }

  &.active {
    .iconWrapper {
      border-color: $color-primary;
    }
  }
  &:nth-child(3) {
    @include breakpoint(sm) {
      order: 4;
    }
  }
}
