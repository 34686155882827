@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  width: 100%;
  padding-bottom: 50px;
  @include breakpoint(sm) {
    padding-bottom: 27px;
  }
}

.contentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;

  @include breakpoint(md) {
    background: $color-white;
    height: auto;
    padding: 16px;
    box-shadow: 0 2px 5px rgba(6, 25, 56, 0.06);
    position: sticky;
    top: 0;
    z-index: 2;
    margin-bottom: 16px;
  }

  @include breakpoint(sm) {
    padding: 0;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 19px;
    row-gap: 11px;
  }

  > a {
    @include breakpoint(sm) {
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.link {
  color: $color-primary;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
}

.backIcon {
  margin-right: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.contentWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include breakpoint(lg) {
    display: block;
  }
}

.content {
  flex: 1;
}

.heading {
  border-bottom: 1px solid $color-primary;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
  @include breakpoint(sm) {
    margin-bottom: 15px;
  }
  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    margin: 0 0 11px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      font-size: 16px;
      margin: 0 0 5px;
    }
  }
}

.collapse {
  margin-bottom: 16px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    font-size: 16px;
    font-weight: 700;
    color: $color-text-dark;
    background: $color-gray-background-dark;
    cursor: pointer;
    height: auto;

    svg {
      color: $color-text;
    }

    &.open {
      svg {
        transform: rotate(180deg);
      }
    }

    &.error {
      color: $color-error;
      background: $color-alert-error-bg;

      .errorMessage {
        font-size: 12px;
        margin-left: 10px;
      }
    }

    span {
      flex: 1;
    }

    .positions {
      font-size: 14px;
      text-align: right;
      color: $color-secondary;
      font-weight: 400;
      display: block;
      margin-right: 10px;
    }
  }

  .content {
    padding: 16px;
    background: $color-white;

    &.hidden {
      display: none;
    }

    &.noStyles {
      padding: 0;
    }

    .errorMessage {
      color: $color-error;
      font-size: 12px;
      margin-left: 10px;
    }
  }
}

.summary {
  border-top: 1px solid $color-primary;
  padding: 35px 75px 0 85px;
  display: flex;

  > div:first-child {
    max-width: 690px;
  }

  @include breakpoint(sm) {
    padding: 0;
    display: block;
    border-top: 0;
  }
}

.comment {
  margin-top: 40px;

  @include breakpoint(sm) {
    margin-top: 15px;
  }

  .header {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: $color-text-dark;
    margin-bottom: 16px;

    @include breakpoint(sm) {
      margin-bottom: 10px;
    }
  }

  .content {
    padding-right: 75px;
    @include breakpoint(sm) {
      padding-right: 0;
    }
  }
}

.textarea {
  padding: 4px;
  outline: none;
  max-width: 100%;
  width: 100%;
  border: 1px solid $color-gray-border;
  margin-right: 75px;

  @include breakpoint(sm) {
    margin-right: 0;
  }
}
