@import 'src/theme/color';
@import 'src/theme/breakpoints';

#about {
  padding: 53px 100px;
  max-width: 1200px;
  margin: 0 auto;

  @include breakpoint(sm) {
    padding: 0;

    h2 {
      display: block !important;
    }

    p {
      font-size: 14px !important;
    }
  }
}

.home-page {
  .htmlBlock {
    max-width: 1300px;
    width: 100%;
    margin: 20px auto;

    @include breakpoint(sm) {
      #about {
        h1 {
          span {
            border-bottom: unset !important;
          }
        }
      }
    }
  }

  @include breakpoint(sm) {
    #about {
      h1 {
        span {
          border-bottom: none !important;
          position: relative;
          display: inline-block;

          &::after {
            position: absolute;
            bottom: 0;
            content: '';
            display: block;
            width: 85%;
            height: 1px;
            background-color: #938585;
          }
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  img {
    @include breakpoint(sm) {
      display: block;
      width: 100%;
      height: unset;
    }
  }

  video {
    @include breakpoint(lg) {
      width: 100vw;
      margin-left: -15px;
    }

    @include breakpoint(lg) {
      margin-left: -25px;
    }
  }
}

.homePlaceholder {
  & > div:first-child {
    max-width: 1920px;
    width: 100%;
    aspect-ratio: 2.56;
    background-color: #e9e9e9;

    @include breakpoint(lg) {
      width: 100vw;
      aspect-ratio: 2.56;
      margin: 0 -25px 15px -25px;
    }
  }

  & > :nth-child(2) {
    max-width: 1300px;
    margin: 0 auto;

    > div {
      width: 100%;
      aspect-ratio: 1.855;
      margin: 20px 0;
      background-color: #e9e9e9;

      &:nth-child(6) {
        width: 100vw;
        margin-left: calc((1300px - 100vw) / 2);
        height: 756px;
        background-color: #f9f9f9;
      }

      &:last-child {
        height: 880px;
        background-color: $color-white;
      }
    }
  }
}

.modal {
  max-width: 675px;
}
