@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  background-color: $color-primary-background;
  display: flex;
  align-items: center;
  height: 50px;
  color: $color-text;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  .container {
    width: 100%;
    padding: 0 15px;

    @media screen and (min-width: 1230px) {
      width: 1230px;
      margin: 0 auto;
    }
  }

  @include breakpoint(sm) {
    position: relative;
  }

  &.simpleHeader {
    opacity: 0.7;
  }

  &:hover {
    opacity: 1;
  }

  .topBar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }

    .user {
      margin-right: 24px;
    }
  }

  .buttons {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
  }

  .myAccountButton {
    button {
      padding: 0;
    }
  }

  .link {
    padding: 0 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color-text-dark;
    text-decoration: none;
    font-size: 13px;

    &.active {
      color: $color-primary;
    }
  }

  .arrowDown {
    color: $color-primary;
    margin-left: 13px;
  }

  .overloginWrapper {
    padding: 0 15px;
    border-left: 1px solid $color-secondary-border;

    button {
      padding: 0;
    }

    table {
      button {
        height: 30px;
      }
    }
  }

  .authButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    font-size: 14px;
    color: $color-text-light;
    margin-left: 12px;
    cursor: pointer;

    svg {
      font-size: 20px;
      margin-left: 12px;
    }
  }
}

.language,
.myAccount {
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-text-dark;
  font-size: 13px;
}

.languagesWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span:first-child {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid $color-gray-border;

    @include breakpoint(sm) {
      margin-right: 15px;
    }
  }
}

.language {
  & > div > span:first-child {
    padding-right: 10px;
  }
}

.filtersWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  button span {
    font-weight: 700;
  }
}

.selectWrapper {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    width: 100%;

    > div {
      width: 100%;
    }
  }
}

.overLoginTableWrapper {
  table {
    @include breakpoint(sm) {
      table-layout: fixed;

      th {
        width: 50%;
        font-weight: 600;
      }

      td {
        overflow-wrap: break-word;
        font-size: 10px;
      }
    }

    td {
      padding-top: 8px;
      padding-bottom: 8px;

      @include breakpoint(sm) {
        padding: 0;
      }
    }

    button {
      height: 30px;
      text-align: left;
    }
  }
}

.userListWrapper {
}

.link {
  padding: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: $color-shopping-button;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  @include breakpoint(sm) {
    font-size: 10px;
    color: initial;
  }
}

.overloginButton {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $color-text;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  height: 25px;
  justify-content: center;
  outline: none;

  @include breakpoint(sm) {
    padding: 0 15px;
    font-size: 18px;
    font-weight: 600;
    color: $color-text;
    margin-top: 30px;
  }

  svg {
    color: $color-primary;
    font-size: 18px;
    margin-left: 10px;

    @include breakpoint(sm) {
      display: none;
    }
  }
}

.cancelWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  border-top: 1px solid $color-gray-border;
}
