@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  width: 100%;
  padding-bottom: 20px;

  .notFound {
    width: 100%;
    text-align: center;
  }
}

.header {
  @include breakpoint(lg) {
    height: auto;
    flex-direction: column;

    .checkoutContainer {
      background-color: $color-white;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 13px 11px;
    }

    .link {
      order: 2;
      align-self: flex-start;
      padding: 10px;
    }
  }
}

.heading {
  border-bottom: 1px solid $color-primary;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
  @include breakpoint(sm) {
    padding-bottom: 5px;
    align-items: center;
  }
  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    margin: 0 0 11px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  button {
    background-color: transparent;
    border: unset;
    display: flex;
    align-items: center;
    height: 50px;
    cursor: pointer;

    &:first-child {
      svg {
        margin-bottom: 5px;
      }
    }

    @include breakpoint(sm) {
      height: auto;
      padding: 0;
      position: relative;
      padding-right: 10px;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      text-transform: uppercase;
      text-decoration: none;
      margin-left: 5px;
      @include breakpoint(sm) {
        font-size: 12px;
      }

      & + svg {
        color: $color-primary;
        align-self: flex-start;
        font-size: 11px;

        @include breakpoint(sm) {
          position: absolute;
          right: 0;
          top: -10px;
        }
      }
    }

    input {
      display: none;
    }
  }
}

.importWrapper,
.exportWrapper {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 10px;
  color: #a69797;
  cursor: pointer;
  padding-left: 0 !important;

  label {
    cursor: pointer;
  }

  span {
    text-decoration: underline;
  }

  svg {
    width: 24px;
    margin-right: 4px;

    path {
      fill: $color-primary;
    }
  }

  input {
    display: none;
  }
}

.mobileActionsWrapper {
  @include breakpoint(sm) {
    display: flex;
    column-gap: 10px;
    margin-top: -25px;
  }
  > div,
  > button {
    @include breakpoint(sm) {
      max-width: 50%;
      margin-bottom: 0;
    }

    > div {
      @include breakpoint(sm) {
        width: 100%;
      }
    }
  }
  button {
    @include breakpoint(sm) {
      text-transform: uppercase;
      height: 35px;
      font-size: 10px;
      border-radius: 0;
      padding: 0;
      width: 100%;
    }
  }
}

.paymentsExpiredData {
  margin-bottom: 24px;
  span,
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  a {
    color: $color-red;
    font-weight: 600;
  }
}

.link {
  color: $color-secondary;
  font-weight: 500;
  text-decoration: none;
  padding: 0;
}

.backIcon {
  margin-right: 8px;
}

.nextIcon {
  margin-right: 8px;
  transform: rotateY(180deg);
}

.contentWrapper {
  @include breakpoint(lg) {
    display: block;
  }
}

.content {
  flex: 1;

  @include breakpoint(lg) {
    .link {
      display: none;
    }
  }
}

.sidebar {
  position: sticky;
  width: 350px;
  top: 20px;
  margin-left: 16px;
  flex-shrink: 0;

  @include breakpoint(lg) {
    width: 100%;
    margin-left: 0;
    position: sticky;
    bottom: calc(-100vh + 100% - 100px);
    z-index: 10;
  }
}

.buttonWrapper {
  display: flex;
}
