@import 'src/theme/color';
@import 'src/theme/breakpoints';
@import 'src/theme/utilities';
@import 'src/theme/font';

.componentWrapper {
  color: $color-text;
  position: relative;
  max-height: 66px;
  height: 100%;

  &.hidden {
    display: none;
  }

  @include breakpoint(md) {
    background: $color-gray-background;
    border: none;
    box-shadow: inset 0 13px 34px -40px rgba(0, 0, 0, 1);
    padding: 17px 16px;
    height: auto;
  }

  @include breakpoint(lg) {
    padding: 0;
  }

  .bottomBar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: inline-flex;
      align-items: center;
      height: 100%;
    }

    @include breakpoint(md) {
      min-width: 100%;
      overflow: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      .categoriesWrapper {
        display: none;
      }
    }
  }

  .categoriesWrapper {
    .categoryButtonLabel {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: $font-poppins;

      svg {
        margin-left: 12px;
      }
    }
  }

  .link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: $color-text-dark;
    margin-left: 64px;
    flex-wrap: nowrap;

    @include breakpoint(md) {
      font-size: 16px;
      margin-left: 0;
      margin-right: 24px;
    }

    &.big {
      font-size: 16px;

      svg {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }
}

.categoriesPopup {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid $color-gray-border;

  .content {
    background: $color-white;
    max-height: calc(100vh - $header-height-desktop - 24px);
    overflow: auto;
  }
}
